<template>
  <div>
    <span v-html="preview"></span>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
  },
  data(){
    return{
        preview:''
    }
  },
  created() {
    const accessToken = useJwt.getToken('accessToken') 
    if(router.currentRoute.params.id){
      // Get edit form data
      this.$http.get('https://api.purplepatch.online/advertiser/banner/preview/'+router.currentRoute.params.id, { 
        headers: {
          Authorization:'Bearer ' + accessToken,
          'x-access-token': accessToken 
        } 
      })
      .then(response => {
        let data = response.data;
        this.preview=data.banner_adtag;
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      })
    }
  },
}
</script>
